import { Grid } from "@mui/material";
import bgImage from "../assets/images/background.png";
import endBgImage from "../assets/images/end-step-background.png";
import LocationTypeStep from "../ui-components/steps/LocationTypeStep";
import StartConfirmStep from "../ui-components/steps/StartConfirmStep";
import { useDispatch, useSelector } from "react-redux";
import { LEADS_STEPS } from "../utils/Constants";
import { setActiveStep } from "../redux/reducers/leadsSlice";
import FormStep from "../ui-components/steps/FormStep";
import EndStep from "../ui-components/steps/EndStep";

export const Home = () => {
  const activeStep = useSelector((state) => state.leads.activeStep);
  const dispatch = useDispatch();

  const handleNext = () => {
    dispatch(setActiveStep(activeStep + 1));
  };

  const onEnd = () => {
    dispatch(setActiveStep(LEADS_STEPS.LEADS_FORM));
  };

  var height = activeStep == LEADS_STEPS.LEADS_FORM ? "100%" : "calc(100vh - 64px)";

  return (
    <>
      <Grid container sx={{ pt: 3, backgroundImage: `url(${activeStep == LEADS_STEPS.LEADS_CONFIRM ? endBgImage : bgImage})`, height: { height }, backgroundSize: "100% 100%", justifyContent: "center" }}>
        <Grid item xs={12} sm={6} md={11}>
          {activeStep === LEADS_STEPS.LOCATION_TYPE && <LocationTypeStep onNext={handleNext} />}
          {activeStep === LEADS_STEPS.START_CONFIRM && <StartConfirmStep onNext={handleNext} />}
          {activeStep === LEADS_STEPS.LEADS_FORM && <FormStep onNext={handleNext} />}
          {activeStep === LEADS_STEPS.LEADS_CONFIRM && <EndStep onNext={onEnd} />}
        </Grid>
      </Grid>
    </>
  );
};
