import axios from "axios";
import { auth } from "./firebase";
import { CONFIG_KEYS } from "./Constants";

const axiosClient = axios.create({
  baseURL: CONFIG_KEYS.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(async function (request) {
  try {
    let idToken = await auth.currentUser?.getIdTokenResult(false);

    if (idToken && idToken.token) {
      request.headers["Authorization"] = "Bearer " + idToken.token;
    }
  } catch (error) {
    console.log("Error obtaining auth token in interceptor, ", error);
  }

  return request;
});

export default axiosClient;
