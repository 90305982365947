import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { CONFIG_KEYS } from "./Constants";

// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: CONFIG_KEYS.FIREBASE_API_KEY,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
var localAuth = getAuth(app);

export const auth = localAuth;
